<template>
  <div class="redBraceletH5_ranking" ref="refreshWrapper">
    <p class="ranking_tip">{{updateDate}}</p>
    <div class="ranking">
      <div
        class="ranking_tab"
        :class="{
          ranking_tab_choose: rankingChoose === 1,
          ranking_tab_left_normal: rankingChoose !== 1,
        }"
        @click="rankingChoose = 1"
      >
        <span>图文类</span>
        <div class="active-bar" v-if="rankingChoose == 1"></div>
      </div>
      <div
        class="ranking_tab"
        :class="{
          ranking_tab_choose: rankingChoose === 3,
          ranking_tab_right_normal: rankingChoose !== 3,
        }"
        @click="rankingChoose = 3"
      >
        <span>视频类</span>
        <div class="active-bar" v-if="rankingChoose == 3"></div>
      </div>
      <div class="ranking_content">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="100"
          :immediate-check="false"
          ref="vanList"
        >
          <div class="ranking_number ranking_list">
            <p class="ranking_title">排名</p>

            <div
              v-for="(item, index) in rankingList"
              :key="index"
              class="ranking_number_content"
            >
              <img
                src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/c9016a905216404cb46ac1ab7420f279.png"
                v-if="index == 0"
              />
              <img
                src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/9e5e2af0e44b4542928528e588f489ee.png"
                v-else-if="index == 1"
              />
              <img
                src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/cb1c23b779fc472bba38fb68ea13fcc1.png"
                v-else-if="index == 2"
              />
              <p v-else>{{ index + 1 }}</p>
            </div>
          </div>
          <div class="ranking_name ranking_list">
            <p class="ranking_title">作品名称</p>
            <div
              v-for="(item, index) in rankingList"
              :key="index"
              class="ranking_name_content"
            >
              <p class="ranking_name_title">
                {{ item.liveName }}
              </p>
              <p class="ranking_name_author">
                {{ item.doctorName + " | " + item.doctorHospital }}
              </p>
            </div>
          </div>
          <div class="ranking_hotValue ranking_list">
            <p class="ranking_title">
              热力值<van-icon name="info-o" @click="hotValueInfo" />
            </p>
            <div
              v-for="(item, index) in rankingList"
              :key="index"
              class="ranking_hotValue_content"
            >
              {{ item.rankValue }}
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <draggable-resizable
      @click.native="jumpRanking"
      class="redBraceletH5_drag"
      style="height: 5rem; width: 5rem"
      :bounds="'parent'"
      ref="draggable"
      :z="999999"
      :isDraggable="true"
      :isResizable="{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }"
    >
      <img
        class="drag_img"
        src="https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230601/62c35829319447d7a2f0dbad5e67c0c4.png"
      />
    </draggable-resizable>
  </div>
</template>
<script>
import DraggableResizable from 'vue-draggable-resizable'

export default {
  components: { DraggableResizable },
  data () {
    return {
      rankingChoose: 1,
      total: 10,
      pageNum: 0,
      loading: false,
      finished: false,
      rankingList: [],
      startY: 0, // 触摸起始位置
      moveY: 0, // 触摸移动距离
      distance: 50, // 判断刷新的最小滑动距离
      disabled: false, // 
      updateDate:''
    };
  },
  name: 'redBraceletH5',
  computed: {
  },
  watch: {
    rankingChoose (newData, oldData) {
      this.pageNum = 0
      this.rankingList = []
      this.loading = false
      this.finished = false;
      this.getRankingList()
    }
  },
  mounted () {
    // 防止在拖动时页面滚动
    const layer = this.$refs.draggable.$el;
    layer.addEventListener('touchmove', (event) => {
      event.preventDefault();
    }, {
      passive: false
    });
    this.getRankingList()
    this.bindEvents()
  },
  methods: {
    jumpRanking () {
      this.$router.push({
        path: '/redBraceletH5/index',
      })
    },
    hotValueInfo () {
      this.$dialog.alert({
        title: '',
        message: '热力值=作品浏览量+该作者管理人数*50',
        className: 'hotValueInfoDialog',
        confirmButtonColor:'#DD0E18'
      }).then(() => {
        // on close
      });
    },
    onLoad () {
      // 异步更新数据
      this.getRankingList()
      this.loading = false
      // 加载状态结束

    },
    getRankingList () {
      if (this.loading == false && this.finished == false) {
        this.pageNum += 1
        this.loading = true;
        this.$api.redBracelet.getRankingList({
          pageNum: this.pageNum,
          pageSize: this.total,
          liveType: this.rankingChoose
        }).then(res => {
          this.loading = false;
          this.rankingList.push(...res.data.pageData.list)
          const date=new Date(Number(res.data.updateDate))
          let month = date.getMonth() + 1
          month = month < 10 ? '0' + month : month
          let day = date.getDate()
          day = day < 10 ? '0' + day : day
          this.updateDate = `数据为${month}月${day}日更新`
          if (this.rankingList.length >= res.data.pageData.total) {
            this.finished = true;
          }
        }).catch(err => {
          this.loading = false;
          this.finished = true
        })
      }
    },
    bindEvents () {
      this.$refs.refreshWrapper.addEventListener('touchstart', this.handleTouchStart, { passive: true })
      this.$refs.refreshWrapper.addEventListener('touchmove', this.handleTouchMove, { passive: true })
      this.$refs.refreshWrapper.addEventListener('touchend', this.handleTouchEnd, { passive: true })
    },
    handleTouchStart (ev) {
      if (this.scrollTop > 0 || this.loading || this.disabled) {
        return
      }
      this.startY = ev.touches[0].pageY
    },
    handleTouchMove (ev) {
      if (this.scrollTop > 0 || this.loading || this.disabled) {
        return
      }
      this.moveY = ev.touches[0].pageY - this.startY
    },
    handleTouchEnd () {
      if (this.scrollTop > 0 || this.loading || this.disabled) {
        return
      }
      if (this.$refs.refreshWrapper.scrollHeight - this.$refs.refreshWrapper.clientHeight + this.moveY < 50&& this.moveY < 50) {
        this.loading = false
        this.getRankingList()
      }
    }
  },


}
</script>
<style lang="less">
.redBraceletH5_ranking {
  position: relative;
  background-image: url(https://cdn-zettayotta-platform-static.medical-force.cn/zettayotta_platform/20230619/c629022997db426ba24c62c396bbfd3f.jpg);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100vw;
  .redBraceletH5_drag {
    bottom: 0;
    position: fixed;
    right: 0;
    bottom: 8rem;
    cursor: pointer;
    .drag_img {
      width: 5rem;
      height: 5rem;
    }
  }
  .ranking_tip{
    width: 9rem;
    font-size: 0.8rem;
    color: #D50D18;
    font-family: PingFangSC-Medium;
    position: relative;
    top: 25vw;
    margin-left: 11vw;
    text-align: justify;
    text-align-last: justify;
  }
  .ranking {
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    top: 10rem;
    background-color: #ffffff;
    border-radius: 1rem 1rem 1rem 1rem;

    .ranking_tab {
      display: inline-block;
      width: 50%;
      text-align: center;
      font-size: 1.2rem;
      font-family: PingFangSC-Semibold;
      padding-top: 0.6rem;
      padding-bottom: 1rem;
      line-height: 2.4rem;
      background-color: #ffffff;
      border-radius: 1rem 1rem 0rem 0rem;
      .active-bar {
        width: 2rem;
        background-color: #DD0E18;
        z-index: 1;
        list-style: none;
        height: 0.3rem;
        border-radius: 2rem;
        margin: 0 auto;
      }
    }
    .ranking_tab_right_normal {
      color: #646a73;
      border-radius: 0rem 1rem 0rem 0rem;
      background: linear-gradient(
        180deg,
        #FFE2E1 5%,
        rgba(232, 222, 255, 0) 100%
      );
      box-shadow: 0 0 0.2rem 0 #fff;
    }
    .ranking_tab_left_normal {
      color: #646a73;
      border-radius: 1rem 0rem 0rem 0rem;
      background: linear-gradient(
        180deg,
        #FFE2E1 5%,
        rgba(232, 222, 255, 0) 100%
      );
      box-shadow: 0 0 0.2rem 0 #fff;
    }
    .ranking_tab_choose {
      transform: translateY(-30%);
    }
    .ranking_content {
      text-align: center;
      .ranking_list {
        min-height: 70vh;
        display: inline-table;
        .ranking_title {
          margin-bottom: 0.8rem;
          color: #8f959e;
          font-size: 1rem;
          font-family: PingFangSC-Regular;
        }
      }
      .ranking_number {
        width: 18%;
        .ranking_number_content {
          margin-bottom: 0.8rem;
          width: 18vw;
          height: 3rem;
          line-height: 3rem;
          img {
            width: 3rem;
            height: 2rem;
          }
          p {
            font-size: 1.2rem;
            font-family: PingFangSC-Semibold;
            color: #bbbfc4;
          }
        }
      }
      .ranking_name {
        width: 60%;
        .ranking_name_content {
          padding-top: 0.05rem;
          margin-bottom: 0.8rem;
          height: 3rem;
          .ranking_name_title {
            width: 55vw;
            font-family: PingFangSC-Medium;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            color: #1f2329;
            font-size: 1rem;
          }
          .ranking_name_author {
            width: 55vw;
            font-size: 0.8rem;
            color: #8f959e;
            font-family: PingFangSC-Regular;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
        }
      }
      .ranking_hotValue {
        width: 18%;
        .ranking_hotValue_content {
          margin-bottom: 0.8rem;
          font-size: 0.9rem;
          height: 3rem;
          font-family: PingFangSC-Regular;
          line-height: 3rem;
          width: 18vw;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
    }
  }
}
.hotValueInfoDialog {
  .van-dialog__content .van-dialog__message {
    font-size: 1.2rem;
    font-family: PingFangSC-Semibold;
    line-height: 2rem;
  }
}
</style>